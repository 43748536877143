import type { IconButtonProps } from "@mui/material/IconButton"

import { useState, useCallback } from "react"

import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Avatar from "@mui/material/Avatar"
import Drawer from "@mui/material/Drawer"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import { useTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"

import { paths } from "src/routes/paths"
import { useRouter, usePathname } from "src/routes/hooks"

import { _mock } from "src/_mock"
import { varAlpha } from "src/theme/styles"

import { Label } from "src/components/label"
import { Iconify } from "src/components/iconify"
import { Scrollbar } from "src/components/scrollbar"
import { AnimateAvatar } from "src/components/animate"

import { useMockedUser } from "src/auth/hooks"

import { UpgradeBlock } from "./nav-upgrade"
import { AccountButton } from "./account-button"
import { SignOutButton } from "./sign-out-button"

// ----------------------------------------------------------------------

export type AccountDrawerProps = IconButtonProps & {
    data?: {
        label: string
        href: string
        icon?: React.ReactNode
        info?: React.ReactNode
    }[]
}

export function AccountDrawer({ data = [], sx, ...other }: AccountDrawerProps) {
    const theme = useTheme()

    const router = useRouter()

    const pathname = usePathname()

    const { user } = useMockedUser() || {}

    const [open, setOpen] = useState(false)

    const handleOpenDrawer = useCallback(() => {
        setOpen(true)
    }, [])

    const handleCloseDrawer = useCallback(() => {
        setOpen(false)
    }, [])

    const handleClickItem = useCallback(
        (path: string) => {
            handleCloseDrawer()
            router.push(path)
        },
        [handleCloseDrawer, router]
    )

    const renderAvatar = (
        <AnimateAvatar
            width={96}
            slotProps={{
                avatar: { src: user?.photoURL, alt: user?.displayName },
                overlay: {
                    border: 2,
                    spacing: 3,
                    color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
                },
            }}
        >
            {user?.displayName?.charAt(0).toUpperCase()}
        </AnimateAvatar>
    )

    return (
        <>
            <AccountButton
                open={open}
                onClick={handleOpenDrawer}
                photoURL={user?.photoURL}
                displayName={user?.displayName}
                sx={sx}
                {...other}
            />

            <Drawer
                open={open}
                onClose={handleCloseDrawer}
                anchor="right"
                slotProps={{ backdrop: { invisible: true } }}
                PaperProps={{ sx: { width: 320 } }}
            >
                <IconButton onClick={handleCloseDrawer} sx={{ top: 12, left: 12, zIndex: 9, position: "absolute" }}>
                    <Iconify icon="mingcute:close-line" />
                </IconButton>

                <Stack alignItems="center" sx={{ pt: 8 }}>
                    {renderAvatar}

                    <Typography variant="subtitle1" noWrap sx={{ mt: 2 }}>
                        {user?.displayName}
                    </Typography>

                    <Typography variant="body2" sx={{ color: "text.secondary", mt: 0.5 }} noWrap>
                        {user?.email}
                    </Typography>
                </Stack>

                <Box sx={{ p: 2.5 }}>
                    <SignOutButton onClose={handleCloseDrawer} />
                </Box>
            </Drawer>
        </>
    )
}
