import type { BoxProps } from "@mui/material/Box"
import type { Breakpoint } from "@mui/material/styles"
import type { ContainerProps } from "@mui/material/Container"

import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import Container from "@mui/material/Container"

import { layoutClasses } from "src/layouts/classes"

import { useSettingsContext } from "src/components/settings"

// ----------------------------------------------------------------------

type MainProps = BoxProps & {
    isNavHorizontal: boolean
}

export function Main({ children, isNavHorizontal, sx, ...other }: MainProps) {
    return (
        <Box
            component="main"
            className={layoutClasses.main}
            sx={{
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                ...(isNavHorizontal && {
                    "--layout-dashboard-content-pt": "40px",
                }),
                ...sx,
            }}
            {...other}
        >
            {children}
        </Box>
    )
}

// ----------------------------------------------------------------------

type DashboardContentProps = ContainerProps & {
    disablePadding?: boolean
}

export function DashboardContent({ sx, children, disablePadding, maxWidth = "xl", ...other }: DashboardContentProps) {
    const theme = useTheme()

    const settings = useSettingsContext()

    const layoutQuery: Breakpoint = "lg"

    return (
        <Container
            className={layoutClasses.content}
            maxWidth={settings.compactLayout ? maxWidth : false}
            sx={{
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                pt: "var(--layout-dashboard-content-pt)",
                pb: "var(--layout-dashboard-content-pb)",
                [theme.breakpoints.up(layoutQuery)]: {
                    px: "var(--layout-dashboard-content-px)",
                },
                ...(disablePadding && {
                    p: {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0,
                        xl: 0,
                    },
                }),
                ...sx,
            }}
            {...other}
        >
            {children}
        </Container>
    )
}
